import React, { useEffect, useState } from "react";
import SpinnerPrimary from "../../../components/Spinner/Spinner";
import BannerImage from "../../../components/Banner/BannerImage";
import { useDispatch, useSelector } from "react-redux";
import { Button, Card, Col, Form, Row, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import axiosGet from "../../../helpers/axiosGet";
import TableContainer from "../../../components/DataTable/TableContainer";
import "../Claim.css";
import { setClaimSubmission } from "../../../store/claim/actions";
import styled from "styled-components";
import uploadDocClaim from "../../../helpers/uploadDocClaimV2";
import FormatSpmData from "../../../components/Formating/FormatSpmData";
import CreateColumnEor from "../../../components/Formating/CreateColumnEor";

const StyledInput = styled.div`
  /* background: #ffffff; */
  background: ${({ isExist }) => (isExist ? "#f5f5f5" : "#ffffff")};
  border: 1px solid #bcbcbc;
  border-radius: 5px;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  div {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
`;

const StyledButton = styled.button`
  padding: 0px 20px;
  font-size: 16px;
  font-weight: 700;
  border-radius: 30px;
  color: white;
  min-height: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(props) =>
    props.isDelete ? "#F93154" : props.isDownload ? "#ADDFFF" : "#27AE60"};
  border: none;
  pointer-events: ${(props) => props.isUpload && "none"};
  min-width: ${(props) => (props.isDelete ? "40px" : "90px")};

  :disabled {
    background: #757575;
  }

  i {
    pointer-events: none;
  }
`;

export default function ClaimSelectAttachmentForm() {
  const isAdminKca = useSelector((state) => state.user.isUserAdminKCa);
  const dataClaim = useSelector((state) => state.claim.dataClaimSubmission);

  const [dataAttachment, setDataAttachment] = useState([]);
  const [headerEor, setHeaderEor] = useState([]);
  const [loading, setLoading] = useState(false);
  const [waiting, setWaiting] = useState(false);
  const [waitingSubmit, setWaitingSubmit] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const fetchClaimCargoContainer = () => {
    axiosGet({
      url: `/claim-kca/id/${dataClaim?.claimContainerId}`,
      callback: (res) => {
        setLoading(false);
        if (res.data.depoCode === "DP002") {
          setHeaderEor(FormatSpmData(res.data.headerEor));
        } else {
          setHeaderEor(res.data.headerEor);
        }
        if (res?.data.eorDone === true) {
          fetchClaimKcaListEor();
        } else {
          // Retry the API call after a delay, if necessary
          setTimeout(fetchClaimCargoContainer, 2000); // Delay of 1 second before retrying
        }
      },
      errorCallback: (err) => {
        setLoading(false);
        console.log(err);
      },
    });
  };

  const fetchClaimKcaListEor = () => {
    axiosGet({
      url: `/claim-kca/list-eor/${dataClaim.claimContainerId}`,
      callback: (res) => {
        setDataAttachment(res.data);
        setLoading(false);
        setWaiting(false);
      },
      errorCallback: (err) => {
        console.log(err);
      },
    });
  };

  useEffect(() => {
    setWaiting(true);
    setLoading(true);
    fetchClaimCargoContainer();

    // eslint-disable-next-line
  }, []);

  const {
    handleSubmit,
    register,
    formState: { errors },
    watch,
  } = useForm({});

  const onSubmit = async (data) => {
    setWaitingSubmit(true);
    const updatedDetails = [];
    const rowLength = dataAttachment?.length;

    for (let index = 0; index < rowLength; index++) {
      const id = data[`idAttachment-${index}`];
      const containerId = data[`container-${index}`];
      // const surveyDate = data[`surveyDate-${index}`];
      // if (containerId && surveyDate) {
      if (containerId) {
        updatedDetails.push({
          id,
          attachmentId: containerId,
          // surveyDate,
        });
      }
    }

    const uploadPromises = dataAttachment
      .map((item, index) => {
        const file = data[`docDocumentation${index}`][0]; // Mengambil file dari data berdasarkan indeks
        if (file) {
          return uploadDocClaim(
            file,
            dataClaim?.refId,
            dataClaim?.claimContainerId,
            "DOC_DOCUMENTATION",
            item.id,
            `docDocumentation${index}`
          );
        }
        return null;
      })
      .filter(Boolean);

    const extractDocDocumentation = (obj) => {
      const result = {};
      for (const key in obj) {
        if (key.startsWith("docDocumentation") && obj[key][0]) {
          result[key] = obj[key][0].name;
        }
      }
      return result;
    };

    //hilangkan params yang terdapat value "docDocumentation"
    const filterDocDocumentation = Object.keys(data)
      .filter((key) => !key.startsWith("docDocumentation"))
      .reduce((obj, key) => {
        obj[key] = data[key];
        return obj;
      }, {});

    const docNamePerIndex = extractDocDocumentation(data);
    dispatch(
      setClaimSubmission({
        dataEor: dataAttachment,
        ...dataClaim,
        details: updatedDetails,
        ...filterDocDocumentation,
        ...docNamePerIndex,
        headerEor: headerEor,
      })
    );

    if (!docNamePerIndex) {
      await Promise.all(uploadPromises);
    }
    await navigate("/v2/perbaikan-kontainer/submission/summary");
  };

  const getDocDocumentationItems = () => {
    const items = {};
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (key.startsWith("docDocumentation")) {
        items[key] = localStorage.getItem(key);
      }
    }
    return items;
  };

  // Retrieve the docDocumentation items
  const docItems = getDocDocumentationItems();

  const filteredAttachment = dataClaim.attachment.filter(
    (item) => item.status === "damaged" && item.isClaimed === false
  );

  const columns = headerEor.map(CreateColumnEor);

  return loading ? (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{ minHeight: "100vh" }}
    >
      <SpinnerPrimary />
    </div>
  ) : (
    <div className="container">
      <BannerImage isAdmin={isAdminKca} />

      <h5 className="pl-4 pr-4 mt-2 mb-3 title-header-left">
        Form Pengajuan Perbaikan
      </h5>
      <Form
        className="form-submission"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
      >
        {waiting ? (
          <Row>
            <Col xs={12} md={12}>
              <Card className="card-wrapper p-4 mb-4">
                <Card.Body className="main-card">
                  <div className="d-flex justify-content-center align-items-center">
                    <div className="align-items-end">
                      Data Eor sedang dimuat. Mohon menunggu{" "}
                      <Spinner
                        animation="grow"
                        size="sm"
                        style={{
                          width: "4px",
                          height: "4px",
                          marginRight: "5px",
                        }}
                      />
                      <Spinner
                        animation="grow"
                        size="sm"
                        style={{
                          width: "4px",
                          height: "4px",
                          marginRight: "5px",
                        }}
                      />
                      <Spinner
                        animation="grow"
                        size="sm"
                        style={{
                          width: "4px",
                          height: "4px",
                          marginRight: "5px",
                        }}
                      />
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        ) : waitingSubmit ? (
          <Row>
            <Col xs={12} md={12}>
              <Card className="card-wrapper p-4 mb-4">
                <Card.Body className="main-card">
                  <div className="d-flex justify-content-center align-items-center">
                    <div className="align-items-end">
                      Sedang memproses data. Mohon menunggu{" "}
                      <Spinner
                        animation="grow"
                        size="sm"
                        style={{
                          width: "4px",
                          height: "4px",
                          marginRight: "5px",
                        }}
                      />
                      <Spinner
                        animation="grow"
                        size="sm"
                        style={{
                          width: "4px",
                          height: "4px",
                          marginRight: "5px",
                        }}
                      />
                      <Spinner
                        animation="grow"
                        size="sm"
                        style={{
                          width: "4px",
                          height: "4px",
                          marginRight: "5px",
                        }}
                      />
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        ) : (
          <>
            <Row>
              <Col xs={12} md={12}>
                <Card className="card-wrapper p-4 mb-4">
                  <Card.Body className="main-card">
                    <Row>
                      {dataAttachment?.length > 0 ? (
                        <>
                          {dataAttachment.map((item, index) => {
                            const docDocumentation = watch(
                              `docDocumentation${index}`
                            ); // Menggunakan watch untuk setiap indeks
                            return (
                              <Col md={12}>
                                <Row className="mb-3">
                                  <Col md={6}>
                                    <Form.Control
                                      type="hidden"
                                      name={`idAttachment-${index}`}
                                      defaultValue={item.id}
                                      {...register(`idAttachment-${index}`)}
                                    />

                                    <Form.Label>Select Container</Form.Label>
                                    <Form.Select
                                      key={index}
                                      name={`container-${index}`}
                                      defaultValue={
                                        dataClaim[`container-${index}`]
                                      }
                                      {...register(`container-${index}`, {
                                        required: "Pilih container!",
                                      })}
                                    >
                                      <option value="" disabled selected>
                                        -- select Container --
                                      </option>
                                      {filteredAttachment?.map((attach) => (
                                        <option
                                          key={attach.id}
                                          value={attach.id}
                                        >
                                          {attach?.containerNo} (
                                          {attach?.sizeType ?? "-"})
                                        </option>
                                      ))}
                                    </Form.Select>
                                    {errors[`container-${index}`] && (
                                      <p className="mb-0 mt-1 text-danger small">
                                        {errors[`container-${index}`].message}
                                      </p>
                                    )}
                                  </Col>
                                  {/* <Col md={6}>
                                <Form.Label>
                                  {isAdminKca
                                    ? "Tanggal Kejadian"
                                    : "Gate In Depo"}
                                </Form.Label>
                                <Form.Control
                                  type="date"
                                  placeholder={`Tanggal ${new Date().getDate()}/${
                                    new Date().getMonth() + 1
                                  }/${new Date().getFullYear()}`}
                                  name={`surveyDate-${index}`}
                                  defaultValue={
                                    new Date().toISOString().split("T")[0]
                                  }
                                  max={new Date().toISOString().split("T")[0]}
                                  min={moment()
                                    .subtract(7, "days")
                                    .format("YYYY-MM-DD")}
                                  {...register(`surveyDate-${index}`, {
                                    required: "tanggal wajib diisi!",
                                  })}
                                />
                                {errors[`surveyDate-${index}`] && (
                                  <p className="mb-0 mt-1 text-danger small">
                                    {errors[`surveyDate-${index}`].message}
                                  </p>
                                )}
                              </Col> */}
                                  <Col md={6}>
                                    <label
                                      htmlFor={`docDocumentation${index}`}
                                      className="mb-2"
                                    >
                                      Foto - foto dokumentasi dan atau rekaman
                                      video (kontainer yang rusak)
                                      <span className="text-danger">
                                        *
                                      </span>{" "}
                                    </label>
                                    <input
                                      type="file"
                                      className="form-control"
                                      id={`docDocumentation${index}`}
                                      name={`docDocumentation${index}`}
                                      {...register(`docDocumentation${index}`, {
                                        required: dataClaim[
                                          `docDocumentation${index}`
                                        ]
                                          ? false
                                          : "Foto - foto dokumentasi dan atau rekaman video wajib diupload!",
                                      })}
                                      style={{ display: "none" }}
                                      accept={
                                        ".doc, .docx, .pdf, .jpg, .jpeg, .png, .zip, .7z, .rar"
                                      }
                                    />

                                    <StyledInput>
                                      <span>
                                        {docDocumentation?.length
                                          ? docDocumentation[0].name
                                          : dataClaim[
                                              `docDocumentation${index}`
                                            ]
                                          ? dataClaim[
                                              `docDocumentation${index}`
                                            ]
                                          : "Pilih file"}
                                      </span>
                                      <div>
                                        {dataClaim[
                                          `docDocumentation${index}`
                                        ] && (
                                          <a
                                            key={index}
                                            className="styled-like-a-button"
                                            download={
                                              dataClaim[
                                                `docDocumentation${index}`
                                              ]
                                            }
                                            href={
                                              docItems[
                                                `docDocumentation${index}`
                                              ]
                                            }
                                            style={{ textDecoration: "none" }}
                                          >
                                            <StyledButton
                                              type="button"
                                              isDownload
                                            >
                                              Unduh
                                            </StyledButton>
                                          </a>
                                        )}
                                        <label
                                          htmlFor={`docDocumentation${index}`}
                                        >
                                          <div style={{ cursor: "pointer" }}>
                                            <StyledButton
                                              type="button"
                                              isUpload
                                            >
                                              Lampirkan
                                            </StyledButton>
                                          </div>
                                        </label>
                                      </div>
                                    </StyledInput>
                                    {errors[`docDocumentation${index}`] && (
                                      <p className="mb-0 mt-1 text-danger small">
                                        *
                                        {
                                          errors[`docDocumentation${index}`]
                                            .message
                                        }
                                      </p>
                                    )}
                                  </Col>
                                  <Col md={12} className="mt-3 text-end">
                                    <small>
                                      SubTotal : <strong>{item.amount}</strong>
                                    </small>
                                  </Col>
                                </Row>

                                <div className="tb-eor mt-3">
                                  <TableContainer
                                    data={item?.eorList}
                                    cols={columns}
                                    eor
                                    form
                                  />
                                </div>
                                <hr />
                              </Col>
                            );
                          })}
                        </>
                      ) : (
                        <div className="text-center">
                          <span>Tidak ada data</span>
                        </div>
                      )}
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>

            <Row className="mt-5">
              <Col lg={12} className="d-flex justify-content-between">
                <Link
                  to={`/v2/perbaikan-kontainer/submission/${dataClaim?.declarationId}`}
                  style={{ textDecoration: "none" }}
                >
                  <Button
                    type="button"
                    color="primary"
                    size="small"
                    className="d-flex justify-content-between align-items-center back-button"
                  >
                    <FontAwesomeIcon icon={faArrowLeft}></FontAwesomeIcon>
                    KEMBALI
                  </Button>
                </Link>
                <Button
                  type="submit"
                  color="primary"
                  size="small"
                  className="d-flex justify-content-between align-items-center back-button"
                >
                  SELANJUTNYA
                  <FontAwesomeIcon icon={faArrowRight}></FontAwesomeIcon>
                </Button>
              </Col>
            </Row>
          </>
        )}
      </Form>
    </div>
  );
}
